import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const paramsFirstStage = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }
    const response = await axios.get(API_URL+"params-first-stage", config)
    
    if( response.data ) {
        return response.data;
    }
}

const calcFirstStage = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }
    const response = await axios.post(API_URL+"calc-first-stage", data, config)
    
    if( response.data ) {
        return response.data
    }
}

const paramsSecondStage = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }
    const response = await axios.post(API_URL+"params-second-stage", data, config)
    
    if( response.data ) {
        return response.data;
    }
}

const calcSecondStage = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }
    const response = await axios.post(API_URL+"calc-second-stage", data, config)
    
    if( response.data ) {
        return response.data
    }
}

const calcThirdStage = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }
    const response = await axios.post(API_URL+"calc-third-stage", data, config)
    
    if( response.data ) {
        return response.data
    }
}

const simulacaoService = { 
    paramsFirstStage,
    calcFirstStage,
    paramsSecondStage,
    calcSecondStage,
    calcThirdStage
    
}

export default simulacaoService;