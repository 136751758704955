import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useAuthStatus = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [loading, setLoading] = useState(true)

    const { user } = useSelector((state) => state.login) 

    useEffect(() => {
        if (user){
            setLoggedIn(true)
        } else{
            setLoggedIn(false)
        }

        setLoading(false)
    }, [user])

    return { loggedIn, loading}
}

