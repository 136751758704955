import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import simulacaoService from "./simulacao.service";


const initialState =  {
    paramsFirstStage: [],
    paramsFirstStageError: false,
    paramsFirstStageSuccess: false,
    paramsFirstStageLoading: false,

    resultFirstStage: null,
    resultFirstStageError: false,
    resultFirstStageSuccess: false,
    resultFirstStageLoading: false,

    paramsSecondStage: [],
    paramsSecondStageError: false,
    paramsSecondStageSuccess: false,
    paramsSecondStageLoading: false,

    resultSecondStage: null,
    resultSecondStageError: false,
    resultSecondStageSuccess: false,
    resultSecondStageLoading: false,

    resultThirdStage: null,
    resultThirdStageError: false,
    resultThirdStageSuccess: false,
    resultThirdStageLoading: false,
}

export const getParamsFirstStage = createAsyncThunk("simulacao/params-first-stage", async (data, thunkAPI) => {
    try {
        return await simulacaoService.paramsFirstStage(data);
    } catch (error) {
        return thunkAPI.rejectWithValue("Internal server Error, try again.");
    }
});

export const calcFirstStage = createAsyncThunk("simulacao/calc-first-stage", async (data, thunkAPI) => {
    try {
        return await simulacaoService.calcFirstStage(data)
    } catch (error) {
        return thunkAPI.rejectWithValue("Internal server Error, try again.");
    }
});

export const getParamsSecondStage = createAsyncThunk("simulacao/params-second-stage", async (data, thunkAPI) => {
    try {
        return await simulacaoService.paramsSecondStage(data);
    } catch (error) {
        return thunkAPI.rejectWithValue("Internal server Error, try again.");
    }
});

export const calcSecondStage = createAsyncThunk("simulacao/calc-second-stage", async (data, thunkAPI) => {

    try {
        return await simulacaoService.calcSecondStage(data)
    } catch (error) {
        return thunkAPI.rejectWithValue("Internal server Error, try again.");
    }
});

export const calcThirdStage = createAsyncThunk("simulacao/calc-third-stage", async (data, thunkAPI) => {

    try {
        return await simulacaoService.calcThirdStage(data)
    } catch (error) {
        return thunkAPI.rejectWithValue("Internal server Error, try again.");
    }
});

export const simulacaoSlice = createSlice({
    name: "simulacao",
    initialState, 
    reducers: {
        reset: (state) => {
            state.paramsFirstStage = [],
            state.paramsFirstStageError = false,
            state.paramsFirstStageSuccess = false,
            state.paramsFirstStageLoading = false,

            state.resultFirstStage = null,
            state.resultFirstStageError = false,
            state.resultFirstStageSuccess = false,
            state.resultFirstStageLoading = false
        },
        resetSecond: (state) => {
            state.paramsSecondStage = [],
            state.paramsSecondStageError = false,
            state.paramsSecondStageSuccess = false,
            state.paramsSecondStageLoading = false,

            state.resultSecondStage = null,
            state.resultSecondStageError = false,
            state.resultSecondStageSuccess = false,
            state.resultSecondStageLoading = false
        },
        resetThird: (state) => {
            state.resultThirdStage = null,
            state.resultThirdStageError = false,
            state.resultThirdStageSuccess = false,
            state.resultThirdStageLoading = false
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getParamsFirstStage.pending, (state) => {
            state.paramsFirstStageLoading = true
        }).addCase(getParamsFirstStage.fulfilled, (state, action) => {
            state.paramsFirstStageLoading = false
            state.paramsFirstStageSuccess = true
            state.paramsFirstStage = action.payload
        }).addCase(getParamsFirstStage.rejected, (state, action) => {
            state.paramsFirstStageLoading = false
            state.paramsFirstStageError = true
            state.paramsFirstStageMessage = action.payload
            state.paramsFirstStage = []
        }).addCase(calcFirstStage.pending, (state) => {
            state.resultFirstStageLoading = true
        }).addCase(calcFirstStage.fulfilled, (state, action) => {
            state.resultFirstStageLoading = false
            state.resultFirstStageSuccess = true
            state.resultFirstStage = action.payload
        }).addCase(calcFirstStage.rejected, (state, action) => {
            state.resultFirstStageLoading = false
            state.resultFirstStageError = true
            state.resultFirstStageMessage = action.payload
            state.resultFirstStage = null
        })

        .addCase(getParamsSecondStage.pending, (state) => {
            state.paramsSecondStageLoading = true
        }).addCase(getParamsSecondStage.fulfilled, (state, action) => {
            state.paramsSecondStageLoading = false
            state.paramsSecondStageSuccess = true
            state.paramsSecondStage = action.payload
        }).addCase(getParamsSecondStage.rejected, (state, action) => {
            state.paramsSecondStageLoading = false
            state.paramsSecondStageError = true
            state.paramsSecondStageMessage = action.payload
            state.paramsSecondStage = []
        }).addCase(calcSecondStage.pending, (state) => {
            state.resultSecondStageLoading = true
        }).addCase(calcSecondStage.fulfilled, (state, action) => {
            state.resultSecondStageLoading = false
            state.resultSecondStageSuccess = true
            state.resultSecondStage = action.payload
        }).addCase(calcSecondStage.rejected, (state, action) => {
            state.resultSecondStageLoading = false
            state.resultSecondStageError = true
            state.resultSecondStageMessage = action.payload
            state.resultSecondStage = null
        })

        .addCase(calcThirdStage.pending, (state) => {
            state.resultThirdStageLoading = true
        }).addCase(calcThirdStage.fulfilled, (state, action) => {
            state.resultThirdStageLoading = false
            state.resultThirdStageSuccess = true
            state.resultThirdStage = action.payload
        }).addCase(calcThirdStage.rejected, (state, action) => {
            state.resultThirdStageLoading = false
            state.resultThirdStageError = true
            state.resultThirdStageMessage = action.payload
            state.resultSThirdStage = null
        })
        
        ;
    }
});

export const { reset, resetSecond, resetThird } = simulacaoSlice.actions;
export default simulacaoSlice.reducer;