import { React, useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { reset, login } from '../../../services/login.slice';
import { Redirect, useNavigate } from 'react-router-dom';

// react-bootstrap components
import {
  Badge,
  Button,
  Card as CardBootstrap,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

function Login() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  

	const { user, isLoading, isSuccess, message, isError } = useSelector(
		(state) => state.login
	);
  
  const [formData, setFormData] = useState({});

  const onChange = (e) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value
      }));
  }

  useEffect(() => {
    if(isSuccess) {
      toast.success("Logado");
      navigate("/nova-simulacao")
    } 
    
    if(isError) {
      toast.error(message == 'Invalid Credentials' ? 'Credenciais Inválidas' : 'Erro ao logar');
    }

    dispatch(reset);
  }, [reset, isError, isSuccess])

  const onSubmit = (e) => {
    
    if(formData.email == undefined || formData.email.length < 6) {
      toast.error('Informe um email válido');
    }

    if(formData.password == undefined || formData.password.length < 6) {
      toast.error('Informe uma senha');
    }

    const data = {
        email: formData.email,
        password: formData.password
    }

    dispatch(login(data))


  }
  

  return (
    <BasicLayout >
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Entrar
          </MDTypography>
          <Grid justifyContent="center" sx={{ mt: 1, mb: 2 }}>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Container fluid>
            <Row>
              <Col md="12">              
                  <Form>
                    <Row>
                      <Col className="pr-1" md="12">
                        <Form.Group controlId="email">
                          <label>Email</label>
                          <Form.Control
                            placeholder="Seu e-mail"
                            type="email"
                            onChange={onChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pr-1" md="12">
                        <Form.Group controlId="password">
                          <label>Password</label>
                          <Form.Control
                            placeholder="Sua senha"
                            type="password"
                            onChange={onChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="mt-2 btn-fill pull-right"
                      variant="info"
                      onClick={onSubmit}
                    >
                      Entrar
                    </Button>
                    <div className="mt-2 clearfix"></div>
                  </Form>
              </Col>
            </Row>
          </Container>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
