import { configureStore } from "@reduxjs/toolkit";
import simulacaoReducer from "../services/simulacao.slice";
import loginReducer from "../services/login.slice";
import perfilReducer from "../services/perfil.slice";

export const store = configureStore({
  reducer: {
      simulacao: simulacaoReducer,
      login: loginReducer,
      perfil: perfilReducer,
  },
});
