// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { React, useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Slide, toast } from 'react-toastify'

import { getParamsFirstStage, calcFirstStage, getParamsSecondStage, calcSecondStage, calcThirdStage } from '../../services/simulacao.slice';

// react-bootstrap components
import Spinner from 'react-bootstrap/Spinner';

import {
  Badge,
  Button,
  Card as CardBootstrap,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

import estados from "./data";
import { getPerfil, updatePerfil } from "services/perfil.slice";

function NovaSimulacao() {
  const { user } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({});

  const {perfil, update} = useSelector(
    (state) => state.perfil
  )
    
  useEffect( () => {
    dispatch(getPerfil({
      token: user ? user.data.token : null
    }));
  }, []);

  const onChange = (e) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value
      }));
  }

  const submit = async (e) => {

    let data = formData;
    data.token = user ? user.data.token : null;
    
    dispatch(updatePerfil(data));
  }

  const validate = (e) => { 
    
    if(!formData.nome) {
      return "Digite um nome.";
    }

    if(!formData.registro) {
      return "Digite um CPF/CNPJ válido.";
    }
    
    if(!formData.celular) {
      return "Digite um número de celular.";
    }
    
    if(!formData.estado) {
      return "Escolha um estado.";
    }
  }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Editar Perfil
                </MDTypography>
              </MDBox>
              <MDBox p={3} component="form" role="form">
                <Container fluid>        
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group controlId="nome">
                        <label>Nome</label>
                        <Form.Control
                          onBlur={onChange}
                          placeholder="Digite o nome"
                          defaultValue={perfil.data ? perfil.data.user.nome : null}
                          type="text"
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="6">
                      <Form.Group controlId="email">
                        <label>Email</label>
                        <Form.Control
                          onBlur={onChange}
                          placeholder="Digite o email"
                          defaultValue={perfil.data ? perfil.data.user.email : null}
                          type="email"
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group controlId="registro">
                        <label>CPF/CNPJ</label>
                        <Form.Control
                          onBlur={onChange}
                          placeholder="Digite o CPF/CNPJ"
                          defaultValue={perfil.data ? perfil.data.user.registro : null}
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group controlId="celular">
                        <label>Celular</label>
                        <Form.Control
                          onBlur={onChange}
                          placeholder="Digite o celular"
                          type="text"
                          defaultValue={perfil.data ? perfil.data.user.celular : null}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="3">
                      <Form.Group controlId="estado">
                        <label htmlFor="exampleInputEmail1">
                          Estado
                        </label>
                        <Form.Select 
                          onBlur={onChange} 
                          className="form-control" 
                        >
                          <option>Estado</option>
                          {perfil.data ? 
                            <option 
                              selected
                              value={perfil.data.user.estado} 
                              key={perfil.data.user.estado}>
                                {perfil.data.user.estado}
                              </option>
                          : null}
                          { estados ? estados.map((estado) => (
                              <option key={estado.sigla} value={estado.sigla}>
                                {estado.sigla}
                              </option>
                            )
                          ) : null} 
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Button
                        className="mt-2 btn-fill pull-right"
                        variant="info" 
                        onClick={submit}
                        mt={2}
                      >
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                  
                  <div className="clearfix"></div>                
                </Container>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NovaSimulacao;
