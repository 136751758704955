// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { React, useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Slide, toast } from 'react-toastify'

import { getParamsFirstStage, calcFirstStage, getParamsSecondStage, calcSecondStage, calcThirdStage } from '../../services/simulacao.slice';

// react-bootstrap components
import Spinner from 'react-bootstrap/Spinner';

import {
  Badge,
  Button,
  Card as CardBootstrap,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

function NovaSimulacao() {
  const { user } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({});
  const [formSecondData, setFormSecondData] = useState({});
  const [formThirdData, setFormThirdData] = useState({});
  const [total, setTotal] = useState(0.00);

  const {reset, paramsFirstStage, paramsFirstStageSuccess, resultFirstStage, resultFirstStageSuccess, resultFirstStageLoading,
    resetSecond, paramsSecondStage, paramsSecondStageSuccess, resultSecondStage, resultSecondStageLoading, resultSecondStageSuccess,
    resultThirdStage, resultThirdStageLoading, resultThirdStageSuccess
  } = useSelector(
    (state) => state.simulacao
  )
    
  useEffect( () => {
    dispatch(getParamsFirstStage({
      token: user ? user.data.token : null
    }));
    dispatch(getParamsSecondStage({
      token: user ? user.data.token : null
    }));
    if (resultFirstStageSuccess) {
      setTotal(Number(resultFirstStage.data.result) + total)
    }
  }, []);

  const showSecondStage = false;
  const showThirdStage = false;

  const onChangeFirstStage = (e) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value
      }));
  }

  const onChangeSecondStage = (e) => {
    setFormSecondData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value
    }));
    
    if(e.target.id == 'tamanho_peca') {
      let data = {
        tamanho_peca_id: e.target.value
      }
      dispatch(getParamsSecondStage(data));
    }
  }

  const onChangeThirdStage = (e) => {
    setFormThirdData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value
    }));
  }


  const validateFirstStage = (e) => { 

    if(!formData.codigo || typeof(formData.codigo) != 'string') {
      return "Informe um código válido.";
    }

    if(!formData.quantidade || !Number.isInteger(parseInt(formData.quantidade))) {
      return "A quantidade deve ser um número inteiro.";
    }

    if(!formData.tipo_materials_id) {
      return "Informe o tipo de material.";
    }

    if(!formData.tipo_gas_id) {
      return "Informe o gás.";
    }

    if(!formData.espessura) {
      return "Informe a espessura.";
    }

    if(!formData.perimetro || isNaN(Number(formData.perimetro))) {
      return "O perímetro deve ser um número.";
    }

    if(!formData.penetracoes || !Number.isInteger(parseInt(formData.penetracoes))) {
      return "A quantidade de penetrações deve ser um número inteiro.";
    }

    return 'ok';
  }

  
  const validateSecondStage = (e) => { 
    
    if(!formSecondData.tamanho_peca || formSecondData.tamanho_peca.length == '0') {
      return "Informe um tamanho válido.";
    }

    if(!formSecondData.dobras || !Number.isInteger(parseInt(formSecondData.dobras))) {
      return "A quantidade de dobras deve ser um número inteiro.";
    }

    return 'ok';
  }

  const validateThirdStage = (e) => { 
    if(!formThirdData.comprimento_cordao || !isNaN(Number(formData.comprimento_cordao))) {
      return "O comprimento do cordão deve ser um número válido";
    }

    return 'ok';
  }

  const submitFirstStage = (e) => {
    e.preventDefault();
    let validateFirst = validateFirstStage(e);
    
    if(validateFirst != 'ok') {
      return toast.error(validateFirst);
    }

    let velocidade = null;

    if(paramsFirstStageSuccess && paramsSecondStageSuccess) {
      velocidade = paramsFirstStage.data.filter(
        (param) => 
          param.tipo_materials_id == formData.tipo_materials_id &&
          param.tipo_gas_id == formData.tipo_gas_id && 
          param.espessura == formData.espessura
      )

      

      const data = {
        codigo_peca: formData.codigo,
        quantidade: formData.quantidade,
        tipo_materials_id: formData.tipo_materials_id,
        tipo_gas_id: formData.tipo_gas_id,
        espessura: formData.espessura,
        perimetro: formData.perimetro,
        penetracoes: formData.penetracoes,

        velocidade: velocidade[0].velocidade,
        hora_maquina: velocidade[0].hora_maquina,
        tempo_penetracao: velocidade[0].tempo_penetracao,
        token: user ? user.data.token : null
      }


      dispatch(calcFirstStage(data))

    }

  }

  const submitSecondStage = (e) => {
    e.preventDefault();
    let validateFirst = validateFirstStage(e);
    let validateSecond = validateSecondStage(e);
    
    if(validateFirst != 'ok') {
      return toast.error(validateFirst);
    }

    if(validateSecond != 'ok') {
      return toast.error(validateSecond);
    }

    let velocidade = null;
    
    if(paramsFirstStageSuccess && paramsSecondStage) {
      velocidade = paramsFirstStage.data.filter(
        (param) => 
          param.tipo_materials_id == formData.tipo_materials_id &&
          param.tipo_gas_id == formData.tipo_gas_id && 
          param.espessura == formData.espessura
      )
      const data = {
        
        quantidade: formData.quantidade,
        tamanho_peca: formSecondData.tamanho_peca,
        dobras: formSecondData.dobras,
  
        hora_maquina: paramsSecondStage.data.golpes_hora.hora_maquina,
        golpes_hora: paramsSecondStage.data.golpes_hora.golpes_hora,
        token: user ? user.data.token : null
      }
      
      dispatch(calcSecondStage(data))
    }


  }

  const submitThirdStage = (e) => {
    e.preventDefault();
    let validateFirst = validateFirstStage(e);
    let validateSecond = validateSecondStage(e);
    let validateThird = validateThirdStage(e);
    
    if(validateFirst != 'ok') {
      return toast.error(validateFirst);
    }

    if(validateSecond != 'ok') {
      return toast.error(validateSecond);
    }

    if(validateThird != 'ok') {
      return toast.error(validateThird);
    }
    
    if(paramsFirstStageSuccess && paramsSecondStage) {
  
      const data = {
        
        
        quantidade: formData.quantidade,
        comprimento_cordao: formThirdData.comprimento_cordao,

        tipo_materials_id: formData.tipo_materials_id,
        tipo_gas_id: formData.tipo_gas_id,
        espessura: formData.espessura,
        tamanho_peca_id: formSecondData.tamanho_peca,
        token: user ? user.data.token : null
      }

      dispatch(calcThirdStage(data))
    }


  }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Cálculo de mão de obra de corte.
                </MDTypography>
              </MDBox>
              <MDBox p={3} component="form" role="form">
                <Container fluid>        
                  <Row>
                    <Col className="pr-1" md="2">
                      <Form.Group controlId="codigo">
                        <label>Código</label>
                        <Form.Control
                          onChange={onChangeFirstStage}
                          placeholder="Digite o código"
                          type="text"
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2">
                      <Form.Group controlId="quantidade">
                        <label>Quantidade</label>
                        <Form.Control
                          onChange={onChangeFirstStage}
                          placeholder="Volume a ser orçado"
                          type="number"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group controlId="tipo_materials_id">
                        <label htmlFor="exampleInputEmail1">
                          Tipo de Material
                        </label>
                        <Form.Select 
                          onChange={onChangeFirstStage} 
                          className="form-control" 
                        >
                          <option>Selecione o Tipo de Material</option>
                          { paramsFirstStageSuccess ? [...new Map(paramsFirstStage.data.map(
                            item => [item['tipo_materials_id'], item])).values()].map((material) => (
                              <option key={material.tipo_materials_id} value={material.tipo_materials_id}>{material.nome_material}</option>
                            )
                          ) : null} 
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group controlId="tipo_gas_id">
                        <label htmlFor="exampleInputEmail1">
                          Gás de Assistência
                        </label>
                        <Form.Select 
                          className="form-control" 
                          onChange={onChangeFirstStage}
                        >
                          <option>Selecione o Gás de Assistência</option>
                          {
                            paramsFirstStageSuccess ? [...new Map(paramsFirstStage.data.filter((param) => param.tipo_materials_id == formData.tipo_materials_id).map(item =>
                              [item['tipo_gas_id'], item])).values()].map(param => (
                                <option key={`gas-${param.tipo_gas_id}`} value={param.tipo_gas_id}>{param.nome_gas}</option>
                              )) : null
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="3">
                      <Form.Group controlId="espessura">
                        <label>Espessura</label>
                        <Form.Select 
                          className="form-control" 
                          onChange={onChangeFirstStage}
                        >
                          <option>Selecione a espessura</option>
                          {
                            paramsFirstStageSuccess ? [...new Map(paramsFirstStage.data.filter((param) => param.tipo_materials_id == formData.tipo_materials_id && param.tipo_gas_id == formData.tipo_gas_id).map(item =>
                              [item['espessura'], item])).values()].map(param => (
                                <option key={`espessura-${param.espessura}`} value={param.espessura}>{param.espessura}</option>
                              )) : null
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="3">
                      <Form.Group controlId="perimetro">
                        <label>Perímetro</label>
                        <Form.Control
                          placeholder="Perímetro"
                          type="number"
                          onChange={onChangeFirstStage}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group controlId="penetracoes">
                        <label>Nº de Penetrações</label>
                        <Form.Control
                          placeholder="Número de penetrações"
                          type="number"
                          onChange={onChangeFirstStage}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Button
                        className="btn-fill pull-right"
                        variant="info"
                        onClick={submitFirstStage}
                      >
                        Calcular
                      </Button>
                    </Col>
                    <Col md="3" className="d-block">
                      <span style={{ fontSize: "25px", color: "gray" }}className="pull-right">Subtotal: R$ { resultFirstStageSuccess ? resultFirstStage.data.result : 0.00  }</span>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>                
                </Container>
              </MDBox>
            </Card>
            { resultFirstStageLoading ? <Spinner animation="border" role="status" size="xl"></Spinner> : null }

            { resultFirstStageSuccess || showSecondStage ? 
            <Card className="mt-5">
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Cálculo de mão de obra de dobra.
                </MDTypography>
              </MDBox>
              <MDBox p={3} component="form" role="form">
                <Container fluid>        
                <Row>
                      <Col className="pr-1" md="3">
                        <Form.Group controlId="tamanho_peca">
                          <label>Tamanho da Peça</label>
                          <Form.Select 
                            className="form-control" 
                            onChange={onChangeSecondStage}
                          >
                            <option value="0">Tamanho de Peça</option>
                            {
                              paramsSecondStageSuccess ? paramsSecondStage.data.tamanhos.map(param => (
                                  <option key={`tamanho-${param.id}`} value={param.id}>{param.tamanho}</option>
                                )) : null
                            }
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group controlId="dobras">
                          <label>Nº de Dobras por Peça</label>
                          <Form.Control
                            placeholder="Número de dobras"
                            type="number"
                            onChange={onChangeSecondStage}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Button
                          className="btn-fill pull-right"
                          variant="info"
                          onClick={submitSecondStage}
                        >
                          Próximo Passo
                        </Button>
                      </Col>
                      <Col md="3" className="d-block">
                        <span style={{ fontSize: "25px", color: "gray" }}className="pull-right">Subtotal: R$ { resultSecondStageSuccess ? resultSecondStage.data.result : 0.00  }</span>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                </Container>
              </MDBox>
            </Card>
            : null }

          { resultSecondStageLoading ? <Spinner animation="border" role="status" size="xl"></Spinner> : null }

          { resultSecondStageSuccess || showThirdStage ? 
            <Card className="mt-5">
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Cálculo de mão de obra de dobra.
                </MDTypography>
              </MDBox>
              <MDBox p={3} component="form" role="form">
                <Container fluid>        
                    <Row>
                      <Col md="3">
                        <Form.Group controlId="comprimento_cordao">
                          <label>Comprimento do cordão de solda</label>
                          <Form.Control
                            placeholder="Número de dobras"
                            type="number"
                            onChange={onChangeThirdStage}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Button
                          className="btn-fill pull-right"
                          variant="info"
                          onClick={submitThirdStage}
                        >
                          Próximo Passo
                        </Button>
                      </Col>
                      <Col md="3" className="d-block">
                        <span style={{ fontSize: "25px", color: "gray" }}className="pull-right">Subtotal: R$ { resultThirdStageSuccess ? resultThirdStage.data.result : 0.00  }</span>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                </Container>
              </MDBox>
            </Card>
            : null }

          </Grid>
        </Grid>
      </MDBox>
      <Card style={{    
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"5rem",
        height:"3.25rem",
        bgColor:"white",
        shadow:"sm",
        position:"fixed",
        right:"2rem",
        bottom:"2rem",
        color:"dark",
        }}>
        { total }
      </Card>
    </DashboardLayout>
  );
}

export default NovaSimulacao;
