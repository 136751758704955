import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import perfilService from "./perfil.service";

const user = JSON.parse(localStorage.getItem("user"))

const initialState =  {
    perfil: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

export const getPerfil = createAsyncThunk("perfil/get", async (data, thunkAPI) => {
    try {
        return await perfilService.perfil(data)
    } catch (error) {
        return thunkAPI.rejectWithValue("Internal server Error, try again.");
    }
});

export const updatePerfil = createAsyncThunk("perfil/update", async (data, thunkAPI) => {
    try {
        return await perfilService.update(data);
    } catch (error) {
        return thunkAPI.rejectWithValue("Internal server Error, try again.");
    }
    
});

export const perfilSlice = createSlice({
    name: "perfil",
    initialState, 
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
            state.user = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPerfil.pending, (state) => {
            state.isLoading = true
        }).addCase(getPerfil.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.perfil = action.payload
        }).addCase(getPerfil.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.isSuccess = false
            state.message = action.payload
            state.perfil = null
        }).addCase(updatePerfil.pending, (state) => {
            state.loading = true
        }).addCase(updatePerfil.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = null
        }).addCase(updatePerfil.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        });
    }
});

export const { reset } = perfilSlice.actions;
export default perfilSlice.reducer;