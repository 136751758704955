import Icon from "@mui/material/Icon";

import NovaSimulacao from "layouts/simulacoes/Nova";
import Login from "layouts/authentication/sign-in/Login";
import Registrar from "layouts/authentication/sign-up/Registrar";
import Perfil from 'layouts/perfil/Perfil';
import PrivateRoute from "components/PrivateRoute";

const routes = [];

routes.push({
  type: "collapse",
  name: "Login",
  key: "login",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/login",
  component: <Login />,
  private: true,
  showInSide: false,
});

routes.push({
  type: "collapse",
  name: "Registrar",
  key: "registrar",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/login",
  component: <Registrar />,
  private: true,
  showInSide: false,
});


routes.push({
  type: "collapse",
  name: "Nova Simulação",
  key: "nova-simulacao",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/nova-simulacao",
  component: <NovaSimulacao />,
  private: true,
  middleware: <PrivateRoute />,
  showInSide: true,
});


routes.push({
  type: "collapse",
  name: "Editar Perfil",
  key: "editar-perfil",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/editar-perfil",
  component: <Perfil />,
  private: true,
  showInSide: false,
});



export default routes;
