import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL;

const perfil = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }
    const response = await axios.post(API_URL+"profile", data, config)

    if( response.data ) {
        return response.data
    }
}

const update = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }

    const response = await axios.post(API_URL+"update-profile", data, config);
    return response.data;

}

const perfilService = { 
    perfil,
    update
}

export default perfilService;